<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>单词管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="单词" prop="spell">
              <el-input
                v-model="queryForm.spell"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="词源" prop="languageOrigin">
              <el-input
                v-model="queryForm.languageOrigin"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组别" prop="level">
              <el-select
                placeholder="请选择"
                v-model="queryForm.level"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_Group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="托福词汇" prop="istoefl">
              <el-select
                placeholder="请选择"
                v-model="queryForm.istoefl"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_istoefl"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="音节" prop="syllables">
              <el-select
                placeholder="请选择"
                v-model="queryForm.syllables"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_syllables"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态" prop="state">
              <el-select
                placeholder="请选择"
                v-model="queryForm.state"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_wordStorageType"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="searchFn()"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="5">
          <span class="title_class">单词管理</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button
            type="primary"
            plain
            @click="importWordExcelDialogVisible = true"
            icon="el-icon-upload2"
            >批量导入</el-button
          >
          <el-button
            type="primary"
            plain
            @click="downloadInfoList"
            icon="el-icon-download"
            >批量导出</el-button
          >
          <el-button type="primary" @click="editDistrictMessage('add')"
            >新增单词+</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="单词（点击播放）" prop="spell">
          <template slot-scope="scope">
            <el-link
              style="color: #409eff"
              @click="handleToWordVoice(scope.row.purl)"
              >{{ scope.row.spell }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column
          label="音标"
          prop="internationalPhoneticAlphabet"
        ></el-table-column>
        <!-- <el-table-column label="词性" prop="partofSpeech"></el-table-column> -->
        <el-table-column label="定义（点击播放）" prop="def" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-link
              style="color: #409eff"
              @click="handleToWordVoice(scope.row.durl)"
              >{{ scope.row.def }}</el-link
            >
          </template></el-table-column
        >
        <el-table-column label="例句（点击播放）" prop="exp" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-link
              style="color: #409eff"
              @click="handleToWordVoice(scope.row.eurl)"
              >{{ scope.row.exp }}</el-link
            >
          </template></el-table-column
        >
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="groupFormatter"
        ></el-table-column>
        <!-- <el-table-column label="托福词汇" prop="istoefl">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.istoefl === 1" size="mini"
              >是</el-tag
            >
            <el-tag type="danger" v-else size="mini">否</el-tag>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="音节" prop="syllables">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.syllables === 2" size="mini"
              >多音节</el-tag
            >
            <el-tag
              type="warning"
              v-else-if="scope.row.syllables === 1"
              size="mini"
              >单音节</el-tag
            >
            <span v-else size="mini">-</span>
          </template>
        </el-table-column> -->
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.state === 0" size="mini"
              >已提交</el-tag
            >
            <el-tag type="success" v-else-if="scope.row.state === 1" size="mini"
              >已入库</el-tag
            >
            <el-tag type="danger" v-else-if="scope.row.state === 2" size="mini"
              >停用</el-tag
            >
            <span v-else size="mini">-</span>
          </template>
        </el-table-column>
        <el-table-column label="词源" prop="languageOrigin"></el-table-column>
        <el-table-column label="入库时间" prop="indataAt"></el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="editDistrictMessage('edit', scope.row)"
              >编辑</el-button
            >
            <!-- <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              style="color: #f56c6c"
              @click="deleteDialog(scope.row.id)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <audio ref="audioPlayer" @ended="overAudio"></audio>
    <wordDetaiEdit
      :show="editChineseCharactersDialogVisible"
      :editWordDetailForm="editWordDetailForm"
      @close="closeFn"
    ></wordDetaiEdit>
    <!-- 批量导入英文单词的Dialog -->
    <el-dialog
      title="批量导入数据"
      :visible.sync="importWordExcelDialogVisible"
      width="30%"
      @close="importChineseWordExcelDialogClose"
    >
      <el-form
        :model="queryForm"
        label-width="100px"
        ref="importChineseWordInfoFormRef"
        :rules="importChineseWordInfoRules"
        style="width: 100%"
      >
        <el-form-item label="组别" prop="cLevel">
          <el-select v-model="queryForm.cLevel">
            <el-option
              v-for="item in dict_Group"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属产品" prop="productType">
          <el-select v-model="queryForm.productType">
            <el-option
              v-for="item in dict_productType"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-upload
          :action="importChineseWordExcel.uploadUrl"
          ref="importChineseWordExcelRef"
          :data="{
            cLevel: this.queryForm.cLevel,
            productType: this.queryForm.productType,
          }"
          :headers="importChineseWordExcel.headerObj"
          :on-progress="importChineseWordExcelProgress"
          :on-success="importChineseWordExcelSuccess"
          :on-error="importChineseWordExcelError"
          :on-exceed="importChineseWordExcelExceed"
          :on-change="onChangeLoad"
          :limit="1"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="small"
            @click="downloadChineseWordExcelTemplate"
            >下载模版</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            @click="uploadChineseWordExcel()"
            >上传词库信息</el-button
          >
        </el-upload>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getWordList, getWordDetailData, WordListExport } from '@/http/api'
import wordDetaiEdit from './components/word_editoradd'
export default {
  components: {
    wordDetaiEdit
  },
  data () {
    return {
      dict_productType: this.$userInfo.getDataList(this, 'p_word_product_type').then(value => { this.dict_productType = value }),
      importChineseWordInfoRules: {
        cLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        productType: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      importChineseWordExcel: {
        uploadUrl: this.$env.baseIP + 'lexicon/importCWorldExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      isUploadFile: false, // 是否已选择上传文件
      importWordExcelDialogVisible: false,
      editChineseCharactersDialogVisible: false,
      playVoiceUrl: '',
      dict_syllables: this.$userInfo.dict_syllables(),
      dict_istoefl: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: 1
        },
        {
          dictCode: 0,
          dictLabel: '否',
          dictValue: 0
        }
      ],
      editWordDetailForm: {},
      dataList: [],
      total: 0,
      dict_Group: this.$userInfo.dict_group(),
      dict_wordStorageType: this.$userInfo.dict_wordStorage(),
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      editVisible: false,
      editTitle: ''
    }
  },
  created () {
    this.getWordList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取单词列表
    getWordList () {
      getWordList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getWordList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getWordList()
    },
    // 查询
    searchFn () {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 10
      this.getWordList()
    },
    // 重置
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.searchFn()
    },
    // 修改单词
    editDistrictMessage (type, rowData) {
      console.log('type', type)
      if (type === 'edit') {
        getWordDetailData(rowData.id).then((res) => {
          this.editWordDetailForm = res.data
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
      this.editChineseCharactersDialogVisible = true
    },
    closeFn () {
      this.editChineseCharactersDialogVisible = false
    },
    // 单词点击事件
    handleToWordVoice (voiceUrl) {
      this.$refs.audioPlayer.src = voiceUrl
      this.$refs.audioPlayer.play()
    },
    overAudio () {

    },
    // 下载字库模板
    async downloadChineseWordExcelTemplate () {
      var downLoadUrl = this.$env.baseIP + 'lexicon/importTheLexiconTemplate'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', {})
    },
    // 监听上传文件变化
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 监听上传文件弹窗关闭事件
    importChineseWordExcelDialogClose () {
      this.isUploadFile = false
    },
    // 上传前判断文件是否为空
    uploadChineseWordExcel () {
      if (this.isUploadFile) {
        this.$refs.importChineseWordExcelRef.submit()
      } else {
        this.$message.error('请先选择要上传的文件')
      }
    },
    // 上传文件超时
    importChineseWordExcelExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.importChineseWordExcelRef.clearFiles()// 清除文件
      this.$refs.importChineseWordExcelRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    // 上传文件加载中
    importChineseWordExcelProgress () {
    },
    // 上传文件成功回调
    importChineseWordExcelSuccess (res) {
      console.log('importChineseWordExcelSuccess', res)
      this.$curUtils.closeLoading(this)
      this.isUploadFile = false
      if (res.code !== 200) {
        this.$refs.importChineseWordExcelRef.clearFiles()// 清除文件
        return this.$alert('请修改后再上传，失败原因:' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('上传内容成功')
      this.getWordList()
      this.$refs.importChineseWordExcelRef.clearFiles()// 清除文件
      this.importWordExcelDialogVisible = false
    },
    // 上传文件失败回调
    importChineseWordExcelError (error) {
      console.log('importChineseWordExcelError', error)
      this.$curUtils.closeLoading(this)
      this.$message.error('上次文件失败')
    },
    // 下载字库列表文件
    async downloadInfoList () {
      WordListExport(self.queryForm).then(res => {
        var url = res.data
        var link = document.createElement('a')
        try {
          link.href = url
        } catch (error) {
          link.href = window.URL.createObjectURL(url)
        }
        link.click()
      })
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === data.level) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>
